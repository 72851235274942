import "Styles/app.css";
import { Routes, Route, useLocation } from "react-router-dom";
import React, {
	lazy,
	Suspense,
	useEffect,
	useState,
	createContext,
	Dispatch,
	SetStateAction,
} from "react";

import AppServiceProvider from "Common/Services/AppServiceProvider";
import { notifications } from "Common/Services/notifications";

const Loader = lazy(() => import("Components/Loader"));
const Layout = lazy(() => import("Layout"));
const FAQs = lazy(() => import("Pages/FAQs"));
const Home = lazy(() => import("Pages/Home"));
const About = lazy(() => import("Pages/About"));
const Features = lazy(() => import("Pages/Features"));

const Terms = lazy(() => import("Pages/Legal/Terms"));
const Privacy = lazy(() => import("Pages/Legal/Privacy"));
const Cookies = lazy(() => import("Pages/Legal/Cookies"));


interface ContextI {
	cityName: string;
	setCityName: Dispatch<SetStateAction<string>>;
	introEnded: boolean;
	setIntroEnded: Dispatch<SetStateAction<boolean>>;
}

export const CityLocationContext = createContext<ContextI>({
	cityName: "",
	setCityName: () => {},
	introEnded: false,
	setIntroEnded: () => {},
});

const App = () => {
	const [ isLoading, setLoading ] = useState<boolean>(false);
	const [ cityName, setCityName ] = useState<string>("New York, USA");
	const [ introEnded, setIntroEnded ] = useState(false);

	const { pathname } = useLocation();

	useEffect(() => {
		setLoading(true);

		AppServiceProvider.loadResources([])
			.then(() => {
				setLoading(false);
			})
			.catch(() => {
				setLoading(true);
			});

		document.body.style.setProperty("--height", innerHeight + "px");

		addEventListener("resize", () => {
			document.body.style.setProperty("--height", innerHeight + "px");
		});
	}, []);

	useEffect(() => {
		notifications.hide();
		scrollTo(0, 0);
	}, [ pathname ]);


	// if (isLoading) return <Loader />;

	return (
		<CityLocationContext.Provider value={{ cityName, setCityName, introEnded, setIntroEnded }}>
			<Layout>
				<Routes>
					<Route
						path="/"
						element={
							<Suspense fallback={null}>
								<Home />
							</Suspense>
						}
					/>
					<Route
						path="/about"
						element={
							<Suspense fallback={null}>
								<About />
							</Suspense>
						}
					/>
					<Route
						path="/faqs"
						element={
							<Suspense fallback={null}>
								<FAQs />
							</Suspense>
						}
					/>
					<Route
						path="/features"
						element={
							<Suspense fallback={null}>
								<Features />
							</Suspense>
						}
					/>

					<Route
						path="/terms-and-conditions"
						element={
							<Suspense fallback={null}>
								<Terms />
							</Suspense>
						}
					/>

					<Route
						path="/privacy-policy"
						element={
							<Suspense fallback={null}>
								<Privacy />
							</Suspense>
						}
					/>

					<Route
						path="/cookie-policy"
						element={
							<Suspense fallback={null}>
								<Cookies />
							</Suspense>
						}
					/>
				</Routes>
			</Layout>
		</CityLocationContext.Provider>

	);
};

export default App;
