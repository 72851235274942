import { TResource } from "Common/Types/TResource";

const AppServiceProvider = {
	loadResources: (resources: TResource[]) =>
		new Promise((resolve) => {
			const promises = resources.map((resource) => import(resource.url));
			Promise.all(promises).then(() => resolve(true));
		}),
};

export default AppServiceProvider;
