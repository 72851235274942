export const notifications = {
	show(data) {
		const event = new CustomEvent("mark/notification/show", { detail: data });
		setTimeout(() => {
			dispatchEvent(event);
		}, 50);
	},
	hide() {
		const event = new CustomEvent("mark/notification/hide");
		dispatchEvent(event);
	},
};